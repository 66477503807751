var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "authWrapper"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row whiteBG-wrap"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-6 col-md-6"
  }, [_c('div', {
    staticClass: "auth-form-wrap signup-form"
  }, [_c('div', {
    staticClass: "auth-linkBtn text-end"
  }, [_c('router-link', {
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("Login")]), _c('router-link', {
    attrs: {
      "to": "/sign-up"
    }
  }, [_vm._v("Sign Up")])], 1), _c('div', {
    staticClass: "auth-form"
  }, [_c('h2', [_vm._v("Sign Up")]), _c('p', [_vm._v("To confirm and register your account, please complete the fields below.")]), _vm._m(1), _c('router-link', {
    staticClass: "pw-reset-btn",
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("Already have an Account?")])], 1)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-6 col-md-6 no-padding"
  }, [_c('div', {
    staticClass: "left-bg-wrapper text-center"
  }, [_c('h1', [_vm._v("Welcome")]), _c('p', [_vm._v("Register to create your online access to your member dashboard. (Please note online account access is not available until your start date or thereafter.)")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', [_c('div', {
    staticClass: "form-group mb-3"
  }, [_c('label', [_vm._v("Last Name")]), _c('input', {
    staticClass: "form-control auth-form-input",
    attrs: {
      "type": "text",
      "placeholder": "Enter your Last Name"
    }
  })]), _c('div', {
    staticClass: "form-group mb-4"
  }, [_c('label', [_vm._v("Last 4 Digits of SSN")]), _c('input', {
    staticClass: "form-control auth-form-input",
    attrs: {
      "type": "text",
      "placeholder": "0123"
    }
  })]), _c('div', {
    staticClass: "form-group row mb-4"
  }, [_c('label', [_vm._v("Date of Birth")]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('select', {
    staticClass: "form-control auth-form-input"
  }, [_c('option', [_vm._v("Month")]), _c('option', [_vm._v("Jan")]), _c('option', [_vm._v("Feb")]), _c('option', [_vm._v("March")]), _c('option', [_vm._v("April")]), _c('option', [_vm._v("May")]), _c('option', [_vm._v("June")]), _c('option', [_vm._v("July")]), _c('option', [_vm._v("Aug")]), _c('option', [_vm._v("Sept")]), _c('option', [_vm._v("Oct")]), _c('option', [_vm._v("Nov")]), _c('option', [_vm._v("Dec")])])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('select', {
    staticClass: "form-control auth-form-input"
  }, [_c('option', [_vm._v("Day")])])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('select', {
    staticClass: "form-control auth-form-input"
  }, [_c('option', [_vm._v("Year")])])])]), _c('div', {
    staticClass: "form-group mb-4"
  }, [_c('button', {
    staticClass: "submit-btn",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Register")])])]);
}]

export { render, staticRenderFns }