<template>
    <div class="authWrapper">
        <div class="container">
            <div class="row whiteBG-wrap">
                <div class="col-lg-6 col-md-6 no-padding">
                    <div class="left-bg-wrapper text-center">
                        <h1>Welcome</h1>
                        <p>Register to create your online access to your member dashboard. (Please note online account access is not available until your start date or thereafter.)</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="auth-form-wrap signup-form">
                        <div class="auth-linkBtn text-end">
                            <router-link to="/login">Login</router-link>
                            <router-link to="/sign-up">Sign Up</router-link>
                        </div>
                        <div class="auth-form">
                            <h2>Sign Up</h2>
                            <p>To confirm and register your account, please complete the fields below.</p>
                            <form>
                                <div class="form-group mb-3">
                                    <label>Last Name</label>
                                    <input type="text" placeholder="Enter your Last Name" class="form-control auth-form-input">
                                </div>
                                <div class="form-group mb-4">
                                    <label>Last 4 Digits of SSN</label>
                                    <input type="text" placeholder="0123" class="form-control auth-form-input">
                                </div>
                                <div class="form-group row mb-4">
                                    <label>Date of Birth</label>
                                    <div class="col-md-4">
                                        <select class="form-control auth-form-input">
                                            <option>Month</option>
                                            <option>Jan</option>
                                            <option>Feb</option>
                                            <option>March</option>
                                            <option>April</option>
                                            <option>May</option>
                                            <option>June</option>
                                            <option>July</option>
                                            <option>Aug</option>
                                            <option>Sept</option>
                                            <option>Oct</option>
                                            <option>Nov</option>
                                            <option>Dec</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <select class="form-control auth-form-input">
                                            <option>Day</option>
                                        </select>
                                    </div><div class="col-md-4">
                                        <select class="form-control auth-form-input">
                                            <option>Year</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <button type="submit" class="submit-btn">Register</button>
                                </div>
                            </form>
                            <router-link to="/login" class="pw-reset-btn">Already have an Account?</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SignUp',
}
</script>
<style src="../assets/css/authen.css" scoped></style>